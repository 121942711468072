import React, { useEffect, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Select, Button, Space, Image, Radio, TreeSelect } from 'antd';
import { LeftCircleTwoTone, RightCircleTwoTone } from '@ant-design/icons';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from "@/script/runAsync.mjs";
import useDocTitle from '@/hooks/useDocTitle.mjs';
import FileUtils from '@/script/FileUtils';
import openWindow from '@/script/openWindow.mjs';
import Ue from './Ue.jsx';

const Pane = () => {
    const [searchParams] = useSearchParams()
    const uiParam = Object.fromEntries(searchParams.entries());

    // 获取URL参数
    const uiId = uiParam.uiId;
    const uiName = uiParam.uiName;

    useDocTitle(uiName);
    const { Option } = Select;
    const http = useHTTP();

    const ref = useRef({ count: 0, preIds: [] });
    const [uid, setUid] = useState(uiId); // 当前界面ID
    const [lid, setLid] = useState(); // 跳转界面ID

    const [dc, setDc] = useState({}); // 控件保存为一条数据
    const [ues, setUes] = useState([]);   // 界面自己的事件
    const [pues, setPues] = useState([]); // 父级界面的实际
    const [pics, setPics] = useState([]);

    const [ui, setUi] = useState({});
    const [uis, setUis] = useState([]);
    const [linkUes, setLinkUes] = useState([]);
    const [index, setIndex] = useState(0);

    useEffect(() => {
        init(uiId);
    }, []);

    const init = async (_uiUi) => {
        try {
            const data = await runAsync(
                async () => {
                    return await http.get(`/umuis/demo?pkid=${_uiUi}`);
                }, {
                error: { content: (err) => `查询失败: ${err.message}` },
                loading: { show: true },
                success: { show: false },
            });

            // 处理UI树
            if (data.uiTree) {
                const arr = [data.uiTree];
                setUis(arr.map(literateUi));
            }

            setUi(data.umUi);
            setLinkUes(data.linkUes ?? []);
            setPics(data.umUiPics ?? []);
            setUes(data.umUes ?? []);
            setPues(data.parentUes ?? []);

            if (data.umUiDcs && data.umUiDcs.length > 0) {
                setDc(data.umUiDcs[0]);
            }
        } catch (err) {
            console.log(err);
        }
    }

    // 遍历UI
    const literateUi = ({ pkid, uiName, children }) => {
        if (children && children.length > 0) {
            return { value: pkid, title: uiName, children: children.map(literateUi) };
        } else {
            return { value: pkid, title: uiName };
        }
    }

    // 刷新界面
    const refreshUi = () => {
        if (ref.current.preIds.length == 0) {
            init(uiId);
            setUid(uiId);
        } else {
            const lastId = ref.current.preIds[ref.current.preIds.length - 1];
            init(lastId);
            setUid(lastId);
        }

        setLid(null);
    }

    // 返回界面
    const backUi = () => {
        if (ref.current.preIds && ref.current.preIds.length > 1) {
            const lastId = ref.current.preIds[ref.current.preIds.length - 2];
            init(lastId);
            setUid(lastId);
            setLid(null);
            ref.current.preIds.pop();
        }
    }

    // 加载界面
    const loadUi = () => {
        if (ref.current.newId) {
            pushBack(ref.current.newId);
            init(ref.current.newId);
            setUid(ref.current.newId);
            setLid(null);
        }

        ref.current.newId = null;
    }

    // 新开界面
    const openUi = () => {
        if (ref.current.newId) {
            openWindow('/UiDemo', { uiId: ref.current.newId, uiName: ref.current.newName });
        }
    }

    const pushBack = (id) => {
        if (ref.current.preIds.length == 0) {
            ref.current.preIds.push(uiId);
        }
        ref.current.preIds.push(id);

    }

    // 加载控件
    const loadDc = (ui) => {
        loadMap(ui);
    }

    // 加载事件
    const loadUe = (ui, ueCat, ueName) => {
        let res = '界面事件能力/';
        if (ueCat) {
            for (let cat of ueCat.split('@@')) {
                res += cat + '/';
            }
        }
        res += ueName;
        loadMap(ui, res);
    }

    const loadMap = (ui, name) => {
        const winParam = 'height=500, width=1000, left=300, top=200, toolbar=no, menubar=no, scrollbars=yes,resizable=yes, location=no, status=no';

        let mapPath = '/' + ui.uiName + '/';
        if (name) {
            mapPath = mapPath + name + '/';
        }

        window.open("/DpMap?id=" + ui.pkid + "&name=" + ui.uiName + "&type=UI" + "&mapPath=" + mapPath, '_blank', winParam);
    }

    const pre = () => {
        if (pics && pics.length > 0) {
            let _index = 0;

            if (index == 0) {
                _index = pics.length - 1;
            } else {
                _index = index - 1;
            }

            setIndex(_index);
        }
    }

    const next = () => {
        if (pics && pics.length > 0) {
            let _index = 0;

            if (pics.length == index + 1) {
                _index = 0;
            } else {
                _index = index + 1;
            }

            setIndex(_index);
        }
    }

    return <div style={{ display: 'flex', height: '100%' }}>
        {/* 左侧布局 */}
        <div style={{ borderRight: '1px #23b1d9 solid', flex: 85 }}>
            {/* 左上侧布局 */}
            {
                pics.length == 0 &&
                <div style={{ borderBottom: '1px blue solid', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '88vh', color: '#d9d9d9', fontSize: '14pt' }}>暂无原型图</div>
            }

            {
                pics.length > 0
                &&
                <div style={{ borderBottom: '1px #23b1d9 solid', display: 'flex', height: '88vh' }}>
                    <div style={{ display: 'flex', flexGrow: '1', order: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                        <LeftCircleTwoTone style={{ fontSize: '50px', color: '#08c' }} onClick={pre} />
                    </div>

                    <div style={{ display: 'flex', order: 2 }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'auto' }}>
                            <img
                                id={'pic_' + index}
                                title={pics[index]?.picName}
                                src={FileUtils.getFileUrl(pics[index]?.picId)}
                                style={{ maxWidth: '100%', maxHeight: '100%' }}
                                // style={{ maxWidth: '75vw', maxHeight: '85vh', width: 'auto', height: 'auto' }}
                                onDoubleClick={e => {
                                    const pic = document.getElementById('pic_' + index);
                                    const obj = document.createElement('img');

                                    obj.id = 'uiPicindex001';
                                    obj.src = pic.src;
                                    obj.style.maxWidth = '100%';
                                    obj.style.maxHeight = '100%';

                                    document.getElementById('showDic').style.display = 'block';
                                    document.getElementById('showPic').appendChild(obj);
                                }}
                            />
                        </div>
                    </div>

                    <div style={{ display: 'flex', flexGrow: '1', order: 3, flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end' }}>
                        <RightCircleTwoTone style={{ fontSize: '50px', color: '#08c' }} onClick={next} />
                    </div>
                </div>
            }

            {/* 左下侧布局 */}
            <div style={{ display: 'flex', height: '12vh', justifyContent: 'center', alignItems: 'center' }}>
                <table>
                    <tr>
                        <td>当前界面：</td>
                        <td>
                            <TreeSelect
                                value={uid}
                                treeData={uis}
                                treeDefaultExpandAll
                                style={{ width: '300px' }}
                                dropdownStyle={{ maxHeight: 800, overflow: 'auto' }}
                                onChange={(e) => {
                                    pushBack(e);
                                    setUid(e);
                                    init(e);
                                }}
                            />
                        </td>
                        <td>
                            <Space>
                                <Button size="middle" type="primary" shape="round" ghost onClick={refreshUi} style={{ marginLeft: '10px' }}>刷新</Button>
                                <Button size="middle" type="primary" shape="round" ghost onClick={backUi} disabled={ref.current.preIds.length < 2}>返回</Button>
                            </Space>
                        </td>
                        <td width={50}></td>

                        <td>跳转界面：</td>
                        <td>
                            <Select size="middle" style={{ width: '300px' }} defaultValue={lid} key={lid}
                                onChange={(e, option) => {
                                    ref.current.newId = e;
                                    ref.current.newName = option.children;
                                    setLid(e);
                                }}
                            >
                                {
                                    linkUes.map(v => {
                                        const name = v.ueName + ' -> ' + v.uiName;
                                        return <Option value={v.uiId}>{name}</Option>;
                                    })
                                }
                            </Select>
                        </td>
                        <td>
                            <Space style={{ marginLeft: '10px' }}>
                                <Button size="middle" type="primary" shape="round" ghost onClick={loadUi} disabled={!lid}>加载</Button>
                                <Button size="middle" type="primary" shape="round" ghost onClick={openUi} disabled={!lid}>新开</Button>
                            </Space>
                        </td>
                    </tr>

                    {/* <tr>
                        <td style={{ textAlign: 'right', height: '50px' }}>原型图：</td>
                        <td colSpan={4}>
                            <Radio.Group defaultValue={pid} key={pid}
                                buttonStyle="solid"
                                onChange={(e) => {
                                    setPid(e.target.value);
                                    setIndex(e.target.index);
                                }}
                            >
                                {
                                    pics.map((item, index) => <Radio.Button value={item.picId} index={index}>{item.picName}</Radio.Button>)
                                }
                            </Radio.Group>
                        </td>
                    </tr> */}
                </table>
            </div>
        </div>

        {/* 右侧布局 */}
        <div style={{ flex: 15, overflow: 'auto' }}>
            {/* 当前界面 */}
            <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0px 2px 0px' }}>
                <div style={{ padding: '4px 0px', width: '159px', background: '#50c3f7', cursor: 'pointer', textAlign: 'center', fontWeight: 'bold' }}
                    onClick={() => loadDc(ui)} >{ui.uiName}</div>
            </div>

            {
                ues.map(v => <Ue style={{ display: 'flex', justifyContent: 'center', height: '30px' }} onClick={() => loadUe(ui, v.ueCat, v.ueName)}>{v.ueName}</Ue>)
            }

            {/* 父级界面 */}
            {
                pues.map(v => {
                    return (
                        <>
                            {
                                v.seqNo == 1 &&
                                <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0px 2px 0px' }}>
                                    <div style={{ padding: '4px 0px', width: '159px', background: '#50c3f7', cursor: 'pointer', textAlign: 'center', fontWeight: 'bold' }}
                                        onClick={() => loadDc({ pkid: v.uiId, uiName: v.uiName })}>{v.uiName}</div>
                                </div>
                            }

                            <Ue style={{ display: 'flex', justifyContent: 'center', height: '30px' }} onClick={() => loadUe({ pkid: v.uiId, uiName: v.uiName }, v.ueCat, v.ueName)}>{v.ueName}</Ue>
                        </>
                    );
                })
            }
        </div>

        <div
            id="showDic"
            style={{
                display: 'none',
                position: 'absolute',
                left: '0px',
                top: '0px',
                width: '100vw',
                height: '100vh',
                zIndex: '1',
                overflow: 'auto',
                background: '#f0f2f5',

            }}
            onDoubleClick={() => {
                document.getElementById('showPic').innerHTML = '';
                document.getElementById('showDic').style.display = 'none';
            }}
        >
            <div
                id="showPic"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100vw',
                    height: '100vh',
                }}>
            </div>
        </div>
    </div >;
}

export default Pane;