import React, { useEffect, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Select, Button, Space, Image, Radio, TreeSelect, Input, Modal } from 'antd';
import { LeftCircleTwoTone, RightCircleTwoTone } from '@ant-design/icons';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from "@/script/runAsync.mjs";
import useDocTitle from '@/hooks/useDocTitle.mjs';
import openWindow from '@/script/openWindow.mjs';
import FileUtils from '@/script/FileUtils';
import Ue from '@/pages/UiDemo/Ue.jsx';
import SelectBfDialog from '@/pages/MainPage/dialogs/SelectBfDialog';
import SelectSfDialog from '@/pages/MainPage/dialogs/SelectSfDialog';
import oprType from './OprType.js';
import BfLayout from './BfLayout.jsx';
import SfLayout from './SfLayout.jsx';
import SoftwareMap from '@/components/SoftwareMap/SoftwareMap.jsx';

const Pane = () => {
    const [searchParams] = useSearchParams()
    const param = Object.fromEntries(searchParams.entries());

    // 获取URL参数
    const prjId = param.prjId;
    const bfId = param.bfId;
    const bfName = param.bfName;
    const sfId = param.sfId;
    const sfName = param.sfName;
    useDocTitle(sfName ?? bfName);

    const { Option } = Select;
    const http = useHTTP();
    const ref = useRef({ doOpr: false });

    // 业务流程
    const [bmBf, setBmBf] = useState({});   // 流程
    const [bmBjs, setBmBjs] = useState([]); // 作业

    const [bid, setBid] = useState(bfId);
    const [bname, setBname] = useState(bfName);
    const [bjId, setBjId] = useState();
    const [bjName, setBjName] = useState();

    // 系统功能
    const [rmSfs, setRmSfs] = useState([]);   // 功能
    const [scenes, setScenes] = useState([]); // 场景
    const [sfOprs, setSfOprs] = useState([]); // 操作

    const [sid, setSid] = useState(sfId); // 当前功能
    const [sname, setSname] = useState(sfName);
    const [smap, setSmap] = useState();
    const [scene, setScene] = useState(); // 当前场景
    const [oprId, setOprId] = useState(); // 当前操作
    const [oprTitle, setOprTitle] = useState();

    // 作业索引、功能索引、场景索引、操作索引
    const [bjIndex, setBjIndex] = useState(0);
    const [sfIndex, setSfIndex] = useState(0);
    const [sceneIndex, setSceneIndex] = useState(0);
    const [oprIndex, setOprIndex] = useState(0);

    // 界面原型图
    const [ui, setUi] = useState({});
    const [ues, setUes] = useState([]);   // 界面自己的事件
    const [pues, setPues] = useState([]); // 父级界面的实际
    const [uiPics, setUiPics] = useState([]);
    const [uiPicindex, setUiPicIndex] = useState(0);

    // 加载：流程、功能
    const [visibleBf, setVisibleBf] = useState(false);
    const [visibleSf, setVisibleSf] = useState(false);
    const winParam = 'height=500, width=1000, left=300, top=200, toolbar=no, menubar=no, scrollbars=yes,resizable=yes, location=no, status=no';

    // 执行按钮
    const [oprFlag, setOprFlag] = useState(false);

    useEffect(() => {
        if (bfId) {
            loadBf(bfId);
        } else if (sfId) {
            loadSf([sfId]);
        }
    }, []);

    // 加载业务流程
    const loadBf = async (_bfId) => {
        try {
            const data = await runAsync(
                async () => {
                    return await http.get(`/bmbfs/demo?pkid=${_bfId}`);
                }, {
                error: { content: (err) => `查询失败: ${err.message}` },
                loading: { show: true },
                success: { show: false },
            });

            const _bmBf = data?.bmBf ?? {};
            const _bmBjs = data?.bmBjs ?? [];
            const _bmBss = data?.bmBss ?? [];
            const _sfs = data?.rmSfs ?? [];
            const _sfOprs = data?.sfOprs ?? [];

            // 前置处理
            doOprPrefix(_sfOprs);

            // 暂存界面需要的数据
            ref.current.prjId = _bmBf.prjId;
            ref.current.sVer = _bmBf.sVer;
            ref.current.bmBss = _bmBss;    // 暂存所有步骤
            ref.current.allSfs = _sfs;     // 暂存所有功能
            ref.current.allOprs = _sfOprs; // 暂存所有操作

            // 1、业务流程
            setBmBf(_bmBf);
            setBname(_bmBf.bfName);
            setBmBjs(_bmBjs);

            // 2、取第一个作业
            if (_bmBjs.length > 0) {
                const first = _bmBjs[0];
                setBjId(first.pkid);
                setBjName(first.bjName);

                // 构建功能
                doCreateSf(first.bjCode);
            }

            // 3、初始化界面
            initUiDemo();
        } catch (err) {
            console.log(err);
        }
    }

    // 加载系统功能
    const loadSf = async (sfIds) => {
        try {
            const data = await runAsync(
                async () => {
                    return await http.get(`/rmsfs/demo`, { pkids: sfIds });
                }, {
                error: { content: (err) => `查询失败: ${err.message}` },
                loading: { show: true },
                success: { show: false },
            });

            const _rmSfs = data.rmSfs ?? [];
            const _sfOprs = data.sfOprs ?? [];

            if (_rmSfs.length == 0) {
                return Modal.error({ content: '系统功能不存在' });
            }

            // 拦截处理
            doOprPrefix(_sfOprs);

            // 取第一个功能
            const firstSf = _rmSfs[0];
            ref.current.prjId = firstSf.prjId;
            ref.current.sVer = firstSf.sVer;

            ref.current.sf = firstSf;
            ref.current.sfId = firstSf.pkid;
            ref.current.sfName = firstSf.sfName;
            ref.current.ucFlag = firstSf.ucFlag;

            ref.current.allSfs = _rmSfs;
            ref.current.allOprs = _sfOprs;

            // 设置功能
            setRmSfs(_rmSfs);
            setSid(firstSf.pkid);
            setSname(firstSf.sfName);
            setSfIndex(0);

            // 构建场景：设置第一个场景、操作
            doCreateScene(firstSf.sfCode);
            doSetFirstScene();

            // 初始化界面
            initUiDemo();
        } catch (err) {
            console.log(err);
        }
    }

    // 构建功能 For 业务流程
    const doCreateSf = (bjCode) => {
        ref.current.oprFlag = false;

        // 当前作业的步骤
        const bjBss = ref.current.bmBss.filter(v => v.bjCode == bjCode); // 过滤作业下的系统功能
        const bjSfCodes = bjBss.filter(v => v.sfCode).map(v => v.sfCode);
        const bjSfs = ref.current.allSfs.filter(v => bjSfCodes.includes(v.sfCode));

        // 1、功能：给调用的系统功能进排序(顺序跟随作业步骤)
        bjSfs.forEach((item) => {
            const _index = bjSfCodes.indexOf(item.sfCode);
            item.seqNo = (_index > -1) ? _index : 999;
        });
        bjSfs.sort((o1, o2) => o1.seqNo - o2.seqNo);
        setRmSfs(bjSfs);

        // 2、设置第一个功能
        if (bjSfs.length > 0) {
            const bjSf = bjSfs[0];
            setSid(bjSf.pkid);
            setSname(bjSf.sfName);

            ref.current.sf = bjSf;
            ref.current.sfId = bjSf.pkid;
            ref.current.sfName = bjSf.sfName;

            doCreateScene(bjSf.sfCode); // 构建场景
            doSetFirstScene(); // 设置第一个场景与操作
        } else {
            setSid(null);
            setSname(null);
            setSmap(null);

            setScene(null);
            setScenes([]);
            setSceneIndex(0);

            setOprId(null);
            setOprTitle(null);
            setSfOprs([]);

            ref.current.sf = null;
            ref.current.sfId = null;
            ref.current.sfName = null;
            ref.current.sfOprs = [];

            initUiDemo();
        }
    }

    // 构建场景
    // 流程演示页中去除场景下拉框（流程演示中只加载功能主场景的操作），保留功能下拉框和操作下拉框，去除功能加载按钮
    const doCreateScene = (sfCode) => {
        // 遍历出当前功能的所有场景
        const bjSfOprs = ref.current.allOprs.filter(v => v.sfCode == sfCode);
        const bjSfScenes = new Set();

        for (const opr of bjSfOprs) {
            // 流程
            if (bfId && opr.mainFlag) {
                bjSfScenes.add(opr.oprScene);
            }

            // 功能
            if (sfId) {
                bjSfScenes.add(opr.oprScene);
            }
        }

        ref.current.sfOprs = bjSfOprs ?? []; // 缓存当前功能的所有操作
        setScenes(Array.from(bjSfScenes.values()));
    }

    // 设置第一个场景
    const doSetFirstScene = () => {
        const _sfOprs = ref.current.sfOprs; // 获取当前功能的所有操作

        if (_sfOprs.length > 0) {
            const first = _sfOprs[0];

            doSfMap(first.oprScene);
            setScene(first.oprScene);
            setSceneIndex(0);

            setOprId(first.pkid);
            setOprTitle(first.oprTitle);
            setSfOprs(_sfOprs.filter(v => v.oprScene == first.oprScene));

            initUiDemo();
        } else {
            setScene(null);
            setSceneIndex(0);

            setOprId(null);
            setOprTitle(null);
            setSfOprs([]);

            initUiDemo();
        }
    }

    // 界面流转图
    const doSfMap = (_oprScene) => {
        let oprScene = _oprScene;
        if (_oprScene) {
            const arr = _oprScene.split('.');
            if (arr.length == 2) {
                oprScene = arr[1].trim();
            }
        }

        const sf = ref.current.sf;
        const migrateMap = sf?.migrateMap;

        if (migrateMap) {
            const map = JSON.parse(migrateMap);

            // 过滤出当前场景
            const children = map.root.children;

            if (sf?.ucFlag) {
                // 用例
                // map.root.children = children.filter(v => oprScene == v.text);
                const _children = children.filter(v => oprScene == v.text);
                if (_children.length > 0) {
                    map.root.children = [_children[0]];
                }
            } else {
                // 功能
                if (oprScene == '主场景') {
                    map.root.children = [children[0]];
                } else {
                    const other = children[1]; // 其他场景
                    other.children = other.children.filter(v => oprScene == v.text);
                    map.root.children = [other];
                }
            }

            setSmap(map);
        }
    }

    // 加载界面
    const loadUiDemo = async (sfOpr) => {
        if (!sfOpr) {
            initUiDemo();
            return;
        }

        try {
            ref.current.oprFlag = true;
            const param = { prjId: sfOpr.prjId, sVer: sfOpr.sVer, sfCode: sfOpr.sfCode, oprCode: sfOpr.oprCode };

            // 加载UI
            const data = await runAsync(
                async () => {
                    return await http.get(`/rmsfs/ui/demo`, param);
                }, {
                error: { content: (err) => `查询失败: ${err.message}` },
                loading: { show: true, content: '加载用户界面信息' },
                success: { show: false },
            });

            const _ui = data.umUi;
            const _ues = data.umUes ?? [];
            const _pues = data.parentUes ?? [];
            const _pics = data.umUiPics ?? [];

            // UI
            if (_ui) {
                setUi(_ui);
                setUes(_ues);
                setPues(_pues);
            }

            // 原型图片
            if (_pics.length > 0) {
                ref.current.searchUi = true; // 找到图片的标记
                setUiPics(_pics);
                setUiPicIndex(0);
            }
        } catch (err) {
            console.log(err);
        }
    }

    // 初始化界面
    const initUiDemo = () => {
        ref.current.oprFlag = false;
        setOprFlag(false);
        setUi({});
        setUes([]);
        setPues([]);
        setUiPics([]);
    }

    // 添加操作前缀
    const doOprPrefix = (oprs) => {
        let sceneNo = null;
        let count = 0;

        for (const opr of oprs) {
            const type = oprType(opr.oprType);

            if (type) {
                opr.oprTitle = type + '：' + opr.oprTitle;
            }

            // TODO 增加前缀
            if (opr.sceneNo) {
                if (opr.sceneNo != sceneNo) {
                    count++;
                }

                // 处理场景
                opr.oprScene = count + '. ' + opr.oprScene;
            }

            sceneNo = opr.sceneNo;
        }
    }

    // --------------------------------级联操作--------------------------------------
    // 搜索业务流程
    const searchBf = (value) => {
        if (value) {
            loadBf(value.pkid);
        }
    }

    // 搜索系统功能
    // 选多个功能加载到功能下拉框，清空当前流程及业务作业
    const searchSf = (value) => {
        if (value) {
            const sfIds = value.map(v => v.pkid);
            loadSf(sfIds);

            setBid(null);
            setBname(null);
            setBjId(null);
            setBjName(null);
            setBmBjs([]);
        }
    }

    // 下一作业
    const nextBj = () => {
        initUiDemo();

        if (null == bmBjs || bmBjs.length == 0) {
            return;
        }

        // 当前作业
        let size = bmBjs.length;
        let index = bmBjs.map(v => v.pkid).indexOf(bjId);

        // 下一作业
        if (index > -1) {
            const bmBj = (++index == size) ? bmBjs[0] : bmBjs[index];
            setBjId(bmBj.pkid);
            setBjName(bmBj.bjName);

            // 构建功能
            doCreateSf(bmBj.bjCode);
        } else {
            ref.current.sfId = null;
            ref.current.sfName = null;

            setRmSfs([]);
            setScenes([]);
            setSfOprs([]);
        }
    }

    // 选择作业
    const chgBj = (id, title) => {
        initUiDemo();

        const index = bmBjs.map(v => v.pkid).indexOf(id);
        const bmBj = bmBjs[index];

        setBjId(id);
        setBjName(title);
        setBjIndex(index);

        // 构建功能
        doCreateSf(bmBj.bjCode);
    }

    // 选择功能
    const chgSf = (id, name) => {
        initUiDemo();

        // 设置当前功能
        const index = rmSfs.map(v => v.pkid).indexOf(id);
        const sf = rmSfs[index];

        ref.current.sf = sf;
        ref.current.sfId = id;
        ref.current.sfName = name;

        setSid(id);
        setSname(name);
        setSfIndex(index);

        // 构建场景：设置第一个场景与操作
        doCreateScene(sf.sfCode);
        doSetFirstScene();
    }

    // 选择场景
    const chgScene = (title) => {
        initUiDemo();

        // 设置当前场景
        const index = scenes.indexOf(title);
        setScene(title);
        setSceneIndex(index);

        // 设置当前操作
        const oprs = ref.current.sfOprs.filter(v => v.oprScene == title);

        if (oprs.length > 0) {
            const firstOpr = oprs[0];
            setSfOprs(oprs);
            setOprId(firstOpr.pkid);
            setOprTitle(firstOpr.oprTitle);

            doSfMap(firstOpr.oprScene);
            initUiDemo();
        } else {
            setSfOprs([]);
            setOprId(null);
            setOprTitle(null);
            initUiDemo();
        }
    }

    // 选择操作
    // 当选择第一个操作时，需要加载 “提示界面”
    const chgOpr = (id, title) => {
        const index = sfOprs.map(v => v.pkid).indexOf(id);
        setOprId(id);
        setOprTitle(title);
        setOprIndex(index);
        setOprFlag(false);
        ref.current.searchUi = false;

        if (index <= 0) {
            initUiDemo();
        } else {
            searchUi(index - 1);
        }
    }

    // 寻找UI
    const searchUi = async (index) => {
        for (var i = index; i >= 0; i--) {
            if (!ref.current.searchUi) {
                await loadUiDemo(sfOprs[i]);
            }
        }
    }

    // 执行
    // 加载当前操作执行后的界面（如果当前操作执行会迁移到新界面），并将当前操作的下一操作设置为当前操作
    // 如当前操作是已经最后一步，则回到当前场景第一步操作，载入“提示界面”
    const doOpr = () => {
        // 是否执行标记
        ref.current.oprFlag = true;

        if (null == sfOprs || sfOprs.length == 0) {
            initUiDemo();
            return;
        }

        // 当前操作
        // let allOprs = ref.current.allOprs;
        // let allSize = allOprs.length;
        // let allIndex = allOprs.map(v => v.pkid).indexOf(oprId);

        let size = sfOprs.length;
        let index = sfOprs.map(v => v.pkid).indexOf(oprId);

        if (index > -1) {
            // 如果当前操作响应指定了界面，则需要迁移到界面原型图
            loadUiDemo(sfOprs[index]);

            // 最后一次执行
            if (index == size - 1) {
                // 集成用例，持续执行操作，直到最后一个
                // if (ref.current.ucFlag) {
                //     if (allIndex < allSize - 1) {
                //         nextScene();
                //     } else {
                //         setOprFlag(true);
                //     }
                // } else {
                //     // 当前操作是最后1步，当前操作保持不变，执行按钮灰化
                //     setOprFlag(true);
                // }

                // 当前操作是最后1步，当前操作保持不变，执行按钮灰化
                setOprFlag(true);
            } else {
                // 下一操作
                const sfOpr = (++index == size) ? sfOprs[0] : sfOprs[index];
                setOprId(sfOpr.pkid);
                setOprTitle(sfOpr.oprTitle);
                setOprIndex(index);
            }
        }
    }

    // 下一场景
    // 将场景下拉框中当前场景的下一场景设置为当前选中场景，并加载选中场景的用户操作和“提示界面”（参见流程加载逻辑）
    // 如当前场景是最后一个，则下一场景按钮灰化
    const nextScene = () => {
        initUiDemo();

        if (null == scenes || scenes.length == 0) {
            return;
        }

        // 当前场景
        let size = scenes.length;
        let index = scenes.indexOf(scene);

        // 下一场景
        if (index > -1) {
            // 设置当前场景
            const oprScene = (++index == size) ? scenes[0] : scenes[index];

            doSfMap(oprScene);
            setScene(oprScene);
            setSceneIndex(index);

            // 取第一个操作
            const oprs = ref.current.sfOprs.filter(v => v.oprScene == oprScene);
            if (oprs && oprs.length > 0) {
                const firstOpr = oprs[0];
                setOprId(firstOpr.pkid);
                setOprTitle(firstOpr.oprTitle);
                setOprIndex(0);
                setSfOprs(oprs);
            } else {
                setSfOprs([]);
            }
        }
    }

    // 下一功能
    // 将功能下拉框中当前功能的下一功能设置为当前选中功能，并加载选中功能的场景、用户操作和“提示界面”（参见流程加载逻辑）
    // 如当前功能是最后一个，则下一功能按钮灰化
    const nextSf = () => {
        initUiDemo();

        if (null == rmSfs || rmSfs.length == 0) {
            return;
        }

        // 当前功能
        let size = rmSfs.length;
        let index = rmSfs.map(v => v.pkid).indexOf(sid);

        // 下一功能
        if (index > -1) {
            const rmSf = (++index == size) ? rmSfs[0] : rmSfs[index];
            ref.current.sf = rmSf;
            ref.current.sfId = rmSf.pkid;
            ref.current.sfName = rmSf.sfName;

            setSid(rmSf.pkid);
            setSname(rmSf.sfName);
            setSfIndex(index);

            // 构建场景：设置第一个场景与操作
            doCreateScene(rmSf.sfCode);
            doSetFirstScene(ref.current.sfOprs);
        } else {
            ref.current.sf = null;
            ref.current.sfId = null;
            ref.current.sfName = null;

            setSid(null);
            setSname(null);
            setSfIndex(0);

            setScene([]);
            setSfOprs([]);
        }
    }

    // 加载界面
    const loadUi = (ui) => {
        loadMap(ui);
    }

    // 加载事件
    const loadUe = (ui, ueCat, ueName) => {
        let res = '界面事件能力/';
        if (ueCat) {
            for (let cat of ueCat.split('@@')) {
                res += cat + '/';
            }
        }
        res += ueName;
        loadMap(ui, res);
    }

    const loadMap = (ui, name) => {
        let mapPath = '/' + ui.uiName + '/';
        if (name) {
            mapPath = mapPath + name + '/';
        }
        window.open("/DpMap?id=" + ui.pkid + "&name=" + ui.uiName + "&type=UI" + "&mapPath=" + mapPath, '_blank', winParam);
    }

    const pre = () => {
        if (uiPics && uiPics.length > 0) {
            setUiPicIndex((uiPicindex == 0) ? uiPics.length - 1 : uiPicindex - 1);
        }
    }

    const next = () => {
        if (uiPics && uiPics.length > 0) {
            const nextIndex = uiPicindex + 1;
            setUiPicIndex((uiPics.length == nextIndex) ? 0 : nextIndex);
        }
    }

    // 打开流程
    const openBf = (mapPath) => {
        if (bid) {
            // openWindow('/BfDetail', { bfId: bid, bfName: bname, prjId, mapPath });
            window.open("/DpMap?id=" + bid + "&name=" + bname + "&type=BF" + "&mapPath=" + mapPath, '_blank', winParam);
        }
    }

    // 打开功能
    const openSf = (mapPath) => {
        const sf = ref.current.sf;
        const sfId = ref.current.sfId;
        const sfName = ref.current.sfName;

        if (sfId) {
            const type = sf.ucFlag ? 'UC' : 'SF';
            window.open("/DpMap?id=" + sfId + "&name=" + sfName + "&type=" + type + "&mapPath=" + mapPath, '_blank', winParam);
        }
    }

    const cssDemo = {
        fontSize: '15pt',
        overflow: 'auto',
        flex: '1',
        wordWrap: 'break-word',
    }

    const cssTd = {
        width: '12%',
    }

    const cssTd2 = {
        width: '38%',
    }

    return <div style={{ display: 'flex', height: '100%' }}>
        {/* 左侧布局 */}
        <div style={{ borderRight: '1px #f0f2f5 solid', flex: 85 }}>
            {/* 左上侧布局 */}
            {
                (ref.current.oprFlag == false) &&
                <div>
                    {
                        smap ?
                            <div>
                                <div style={{ display: 'flex', height: '8vh', fontSize: '20pt', fontWeight: 'bold', justifyContent: 'center', alignItems: 'center', borderBottom: '1px #f0f2f5 solid' }}>
                                    {ref.current?.sf?.ucFlag ? '集成用例仿真演示' : '功能演示'}
                                </div>
                                <div style={{ borderBottom: '1px #f0f2f5 solid', display: 'flex', height: '80vh', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                                    <SoftwareMap mapData={smap} style={{ flex: 1, height: '100%' }} />
                                </div>
                            </div>
                            :
                            <div style={{ borderBottom: '1px #23b1d9 solid', display: 'flex', height: '88vh', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                                <table css={cssDemo}>
                                    <tr>
                                        <td colSpan={4} align='center' style={{ fontSize: '20pt' }}>
                                            {/* <div style={{ height: '8vh' }}>{sfId ? '功能演示' : '流程演示'}</div> */}
                                            <div style={{ height: '8vh' }}>{ref.current?.sf?.ucFlag ? '用例演示' : '功能演示'}</div>
                                        </td>
                                    </tr>

                                    {
                                        bfId &&
                                        <tr>
                                            <td css={cssTd}>当前业务流程：</td>
                                            <td css={cssTd2}>{bname ? bname : ''}</td>

                                            <td css={cssTd}>当前业务作业：</td>
                                            <td css={cssTd2}>{bjName ? bjName : ''}</td>
                                        </tr>
                                    }

                                    <tr>
                                        <td css={cssTd}>当前系统功能：</td>
                                        <td css={cssTd2}>{sname ? sname : ''}</td>

                                        <td css={cssTd}>当前功能场景：</td>
                                        <td css={cssTd2}>{scene ? scene : ''}</td>
                                    </tr>

                                    <tr style={{ height: '20px' }}>
                                        <td colSpan={4}></td>
                                    </tr>

                                    <tr>
                                        <td css={cssTd}>功能操作步骤：</td>
                                        <td colSpan={3}>
                                            <div style={{ overflow: 'auto', maxHeight: '70vh' }}>
                                                {
                                                    sfOprs.map((v, index) => <div>{v.oprTitle ? (index + 1) + '. ' + v.oprTitle : ''} </div>)
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                    }
                </div>
            }

            {
                (ref.current.oprFlag == true) &&
                (
                    uiPics.length == 0 ?
                        <div style={{ borderBottom: '1px #f0f2f5 solid', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '88vh', color: '#d9d9d9', fontSize: '14pt' }}>暂无界面</div>
                        :
                        <div style={{ borderBottom: '1px #f0f2f5 solid', display: 'flex', height: '88vh' }}>
                            <div style={{ display: 'flex', flexGrow: '1', order: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                                <LeftCircleTwoTone style={{ fontSize: '50px', color: '#08c' }} onClick={pre} />
                            </div>

                            <div style={{ display: 'flex', order: 2 }}>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'auto' }}>
                                    <img
                                        id={'pic_' + uiPicindex}
                                        title={uiPics[uiPicindex]?.picName}
                                        src={FileUtils.getFileUrl(uiPics[uiPicindex]?.picId)}
                                        style={{ maxWidth: '100%', maxHeight: '100%' }}
                                        // style={{ maxWidth: '75vw', maxHeight: '85vh' }}
                                        onDoubleClick={e => {
                                            const pic = document.getElementById('pic_' + uiPicindex);
                                            const obj = document.createElement('img');

                                            obj.id = 'uiPicindex001';
                                            obj.src = pic.src;
                                            obj.style.maxWidth = '100%';
                                            obj.style.maxHeight = '100%';

                                            document.getElementById('showDic').style.display = 'block';
                                            document.getElementById('showPic').appendChild(obj);
                                        }}
                                    />
                                </div>
                            </div>

                            <div style={{ display: 'flex', flexGrow: '1', order: 3, flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end' }}>
                                <RightCircleTwoTone style={{ fontSize: '50px', color: '#08c' }} onClick={next} />
                            </div>
                        </div>
                )
            }

            {/* 左下侧布局 */}
            <div style={{ display: 'flex', height: '12vh', justifyContent: 'center', alignItems: 'center' }}>
                <table>
                    {/* 流程模式 */}
                    {
                        bfId &&
                        <BfLayout
                            sid={sid}
                            sname={sname}
                            oprId={oprId}
                            scene={scene}
                            rmSfs={rmSfs}
                            scenes={scenes}
                            sfOprs={sfOprs}
                            oprFlag={oprFlag}
                            sfIndex={sfIndex}
                            sceneIndex={sceneIndex}
                            chgSf={chgSf}
                            chgOpr={chgOpr}
                            nextScene={nextScene}
                            nextSf={nextSf}
                            doOpr={doOpr}
                            openSf={openSf}
                            setVisibleSf={setVisibleSf}
                            bname={bname}
                            bjId={bjId}
                            bjName={bjName}
                            bmBjs={bmBjs}
                            chgBj={chgBj}
                            nextBj={nextBj}
                            openBf={openBf}
                            setVisibleBf={setVisibleBf}
                        />
                    }

                    {/* 功能模式 */}
                    {
                        sfId &&
                        <SfLayout
                            sf={ref.current.sf}
                            sid={sid}
                            sname={sname}
                            oprId={oprId}
                            scene={scene}
                            rmSfs={rmSfs}
                            scenes={scenes}
                            sfOprs={sfOprs}
                            oprFlag={oprFlag}
                            sfIndex={sfIndex}
                            sceneIndex={sceneIndex}
                            chgSf={chgSf}
                            chgScene={chgScene}
                            chgOpr={chgOpr}
                            nextScene={nextScene}
                            nextSf={nextSf}
                            doOpr={doOpr}
                            openSf={openSf}
                            setVisibleSf={setVisibleSf}
                        />
                    }
                </table>
            </div>
        </div>

        {/* 右侧布局 */}{
            ref.current.oprFlag == true &&
            <div style={{ flex: 15, overflow: 'auto' }}>
                {/* 当前界面 */}
                {
                    (ui && ui.uiName) &&
                    <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0px 2px 0px' }}>
                        <div style={{ padding: '4px 0px', width: '159px', background: '#50c3f7', cursor: 'pointer', textAlign: 'center', fontWeight: 'bold' }} onClick={() => loadUi(ui)}>{ui.uiName}</div>
                    </div>
                }
                {
                    ues.map(v => <Ue style={{ display: 'flex', justifyContent: 'center', height: '30px' }} onClick={() => loadUe(ui, v.ueCat, v.ueName)}>{v.ueName}</Ue>)
                }

                {/* 父级界面 */}
                {
                    pues.map(v => {
                        return (
                            <>
                                {
                                    v.seqNo == 1 &&
                                    <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0px 2px 0px' }}>
                                        <div style={{ padding: '4px 0px', width: '159px', background: '#50c3f7', cursor: 'pointer', textAlign: 'center', fontWeight: 'bold' }}
                                            onClick={() => loadUi({ pkid: v.uiId, uiName: v.uiName })}>{v.uiName}</div>
                                    </div>
                                }

                                <Ue style={{ display: 'flex', justifyContent: 'center', height: '30px' }} onClick={() => loadUe({ pkid: v.uiId, uiName: v.uiName }, v.ueCat, v.ueName)}>{v.ueName}</Ue>
                            </>
                        );
                    })
                }
            </div>
        }

        <SelectBfDialog visible={visibleBf} setVisible={setVisibleBf} onCall={searchBf} prjId={ref.current.prjId} sVer={ref.current.sVer} />
        <SelectSfDialog visible={visibleSf} setVisible={setVisibleSf} onCall={searchSf} prjId={ref.current.prjId} sVer={ref.current.sVer} isSingle={false} sfTypeCode="UI" ucFlag={ref.current.sf?.ucFlag} />

        <div
            id="showDic"
            style={{
                display: 'none',
                position: 'absolute',
                left: '0px',
                top: '0px',
                width: '100vw',
                height: '100vh',
                zIndex: '1',
                overflow: 'auto',
                background: '#f0f2f5',

            }}
            onDoubleClick={() => {
                // const ele = document.getElementById('showPic');
                // const pic = document.getElementById('uiPicindex001');
                // ele.removeChild(pic);
                document.getElementById('showPic').innerHTML = '';
                document.getElementById('showDic').style.display = 'none';
            }}
        >
            <div
                id="showPic"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100vw',
                    height: '100vh',
                }}>
            </div>
        </div>
    </div >;

}

export default Pane;
