import React, { useEffect, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Input, Tabs, Checkbox, Badge, Row, Col } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from "@/script/runAsync.mjs";
import useDocTitle from '@/hooks/useDocTitle.mjs';
import {useUser} from '@/biz/user.mjs'
import BasMapTab from './BasMapTab.jsx';
import BfTab from './BfTab.jsx';
import BdTab from './BdTab.jsx';
import SvrTab from './SvrTab.jsx';
import SfTab from './SfTab.jsx';
import UcTab from './UcTab.jsx';
import UiTab from './UiTab.jsx';
import DtTab from './DtTab.jsx';
import DvTab from './DvTab.jsx';
import CdgTab from './CdgTab.jsx';
import LsiTab from './LsiTab.jsx';
import FcTab from './FcTab.jsx';
import DtoTab from './DtoTab.jsx';
import DesTab from './DesTab.jsx';
import SelectProjectList from '@/pages/MainPage/dialogs/SelectProjectList';

const Pane = () => {
    useDocTitle('搜索');
    const [searchParams] = useSearchParams()
    const param = Object.fromEntries(searchParams.entries());
    const keyword = param.keyword;

    const { TabPane } = Tabs;
    const http = useHTTP();
    const user = useUser()
    const ref = useRef({ count: 0 });

    const options = [
        // -------导航地图-------
        {
            value: 'BIZ_MAP',
            label: '业务地图',
        },
        {
            value: 'DATA_MAP',
            label: '数据地图',
        },
        {
            value: 'SF_MAP',
            label: '功能地图',
        },
        {
            value: 'UI_MAP',
            label: '界面地图',
        },
        {
            value: 'INF_MAP',
            label: '接口地图',
        },
        {
            value: 'FC_MAP',
            label: '程序地图',
        },

        // -------制品地图-------
        {
            value: '_DES_MAP',
            label: '模型交付单',
        },
        {
            value: '_BF_MAP',
            label: '业务流程',
        },
        {
            value: '_BD_MAP',
            label: '业务对象',
        },
        {
            value: '_SVR_MAP',
            label: '业务服务',
        },
        {
            value: '_SF_MAP',
            label: '系统功能',
        },
        {
            value: '_UC_MAP',
            label: '集成用例',
        },
        {
            value: '_UI_MAP',
            label: '用户界面',
        },
        {
            value: '_DT_MAP',
            label: '数据表',
        },
        {
            value: '_DV_MAP',
            label: '数据视图',
        },
        {
            value: '_CDG_MAP',
            label: '标准字段组',
        },
        {
            value: '_LSI_MAP',
            label: '外联接口',
        },
        {
            value: '_FC_MAP',
            label: '程序能力',
        },
        {
            value: '_DTO_MAP',
            label: 'DTO',
        },
    ]

    // -----------------------List-----------------------
    const [bizMapList, setBizMapList] = useState([]);
    const [dataMapList, setDataMapList] = useState([]);
    const [sfMapList, setSfMapList] = useState([]);
    const [uiMapList, setUiMapList] = useState([]);
    const [infMapList, setInfMapList] = useState([]);
    const [fcMapList, setFcMapList] = useState([]);

    // 业务
    const [desList, setDesList] = useState([]);
    const [bfList, setBfList] = useState([]);
    const [bdList, setBdList] = useState([]);
    const [svrList, setSvrList] = useState([]);

    // 功能、界面
    const [sfList, setSfList] = useState([]);
    const [ucList, setUcList] = useState([]);
    const [uiList, setUiList] = useState([]);

    // 数据、接口、程序
    const [dtList, setDtList] = useState([]);
    const [dvList, setDvList] = useState([]);
    const [cdgList, setCdgList] = useState([]);
    const [lsiList, setLsiList] = useState([]);
    const [fcList, setFcList] = useState([]);
    const [dtoList, setDtoList] = useState([]);

    // -----------------------setRows-----------------------
    const [bizMapRows, setBizMapRows] = useState([]);
    const [dataMapRows, setDataMapRows] = useState([]);
    const [sfMapRows, setSfMapRows] = useState([]);
    const [uiMapRows, setUiMapRows] = useState([]);
    const [infMapRows, setInfMapRows] = useState([]);
    const [fcMapRows, setFcMapRows] = useState([]);

    // 业务
    const [desRows, setDesRows] = useState([]);
    const [bfRows, setBfRows] = useState([]);
    const [bdRows, setBdRows] = useState([]);
    const [svrRows, setSvrRows] = useState([]);

    // 功能、界面
    const [sfRows, setSfRows] = useState([]);
    const [ucRows, setUcRows] = useState([]);
    const [uiRows, setUiRows] = useState([]);

    // 数据、接口、程序
    const [dtRows, setDtRows] = useState([]);
    const [dvRows, setDvRows] = useState([]);
    const [cdgRows, setCdgRows] = useState([]);
    const [lsiRows, setLsiRows] = useState([]);
    const [fcRows, setFcRows] = useState([]);
    const [dtoRows, setDtoRows] = useState([]);

    // -----------------------Loading-----------------------
    const [bizMapLoading, setBizMapLoading] = useState([]);
    const [dataMapLoading, setDataMapLoading] = useState([]);
    const [sfMapLoading, setSfMapLoading] = useState([]);
    const [uiMapLoading, setUiMapLoading] = useState([]);
    const [infMapLoading, setInfMapLoading] = useState([]);
    const [fcMapLoading, setFcMapLoading] = useState([]);

    // 业务
    const [desLoading, setDesLoading] = useState(false);
    const [bfLoading, setBfLoading] = useState(false);
    const [bdLoading, setBdLoading] = useState(false);
    const [svrLoading, setSvrLoading] = useState(false);

    // 功能、界面
    const [sfLoading, setSfLoading] = useState(false);
    const [ucLoading, setUcLoading] = useState(false);
    const [uiLoading, setUiLoading] = useState(false);

    // 数据、接口、程序
    const [dtLoading, setDtLoading] = useState(false);
    const [dvLoading, setDvLoading] = useState(false);
    const [cdgLoading, setCdgLoading] = useState(false);
    const [lsiLoading, setLsiLoading] = useState(false);
    const [fcLoading, setFcLoading] = useState(false);
    const [dtoLoading, setDtoLoading] = useState(false);

    // check
    const [checkedList, setCheckedList] = useState(options.map(v => v.value).filter(v => !['BIZ_MAP', 'DATA_MAP', 'SF_MAP', 'UI_MAP', 'INF_MAP', 'FC_MAP'].includes(v)));
    const [checkAll, setCheckAll] = useState(true);
    const [indeterminate, setIndeterminate] = useState(true);

    useEffect(() => {
        init();
        doSearch(keyword);
    }, []);

    const init = () => {
        ref.current.prjId = user?.currPrjId;
        ref.current.sVer = user?.currPrjSver;
        ref.current.keyword = keyword;
    }

    const doSearch = async (keyword) => {
        if (!ref.current.prjId || !keyword) {
            return;
        }

        try {
            ref.current.count = 1;
            ref.current.keyword = keyword;
            const dpTypes = checkedList.length > 0 ? checkedList : options.map(v => v.value);

            for (const dpType of dpTypes) {
                doSearchRecord(dpType, keyword);
            }
        } catch (err) {
            console.log(err);
        } finally {
        }
    }

    const clearSearch = () => {
        setBizMapList([]);
        setDataMapList([]);
        setSfMapList([]);
        setUiMapList([]);
        setInfMapList([]);
        setFcMapList([]);

        setDesList([]);
        setBfList([]);
        setBdList([]);
        setSvrList([]);

        setSfList([]);
        setUcList([]);
        setUiList([]);

        setDtList([]);
        setDvList([]);
        setCdgList([]);

        setLsiList([]);
        setFcList([]);
        setDtoList([]);
    }

    const doSearchRecord = async (searchType, keyword) => {
        try {
            clearSearch();
            setLoad(searchType, true);

            // 搜索统计
            let result = await doSearchTotal(searchType, keyword);

            let data = [];
            let totalRow = result.totalRow;
            let pageSize = result.pageSize;
            let count = Math.ceil(totalRow / pageSize);

            // 分段检索
            for (var i = 0; i < count; i++) {
                let dataList = await doSearchData(searchType, keyword, i * pageSize);
                data = [...data, ...dataList];

                switch (searchType) {
                    case 'BIZ_MAP': setBizMapList(data); break;
                    case 'DATA_MAP': setDataMapList(data); break;
                    case 'SF_MAP': setSfMapList(data); break;
                    case 'UI_MAP': setUiMapList(data); break;
                    case 'INF_MAP': setInfMapList(data); break;
                    case 'FC_MAP': setFcMapList(data); break;
                    // 模型交付单
                    case '_DES_MAP': setDesList(data); break;
                    case '_BF_MAP': setBfList(data); break;
                    case '_BD_MAP': setBdList(data); break;
                    case '_SVR_MAP': setSvrList(data); break;
                    // 功能、界面
                    case '_SF_MAP': setSfList(data); break;
                    case '_UC_MAP': setUcList(data); break;
                    case '_UI_MAP': setUiList(data); break;
                    // 数据、接口、程序
                    case '_DT_MAP': setDtList(data); break;
                    case '_DV_MAP': setDvList(data); break;
                    case '_CDG_MAP': setCdgList(data); break;
                    case '_LSI_MAP': setLsiList(data); break;
                    case '_FC_MAP': setFcList(data); break;
                    case '_DTO_MAP': setDtoList(data); break;
                }
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoad(searchType, false);
        }
    }

    const setLoad = (searchType, loadFlag) => {
        switch (searchType) {
            case 'BIZ_MAP': setBizMapLoading(loadFlag); break;
            case 'DATA_MAP': setDataMapLoading(loadFlag); break;
            case 'SF_MAP': setSfMapLoading(loadFlag); break;
            case 'UI_MAP': setUiMapLoading(loadFlag); break;
            case 'INF_MAP': setInfMapLoading(loadFlag); break;
            case 'FC_MAP': setFcMapLoading(loadFlag); break;
            // 模型交付单
            case '_DES_MAP': setDesLoading(loadFlag); break;
            case '_BF_MAP': setBfLoading(loadFlag); break;
            case '_BD_MAP': setBdLoading(loadFlag); break;
            case '_SVR_MAP': setSvrLoading(loadFlag); break;
            // 功能、界面
            case '_SF_MAP': setSfLoading(loadFlag); break;
            case '_UC_MAP': setUcLoading(loadFlag); break;
            case '_UI_MAP': setUiLoading(loadFlag); break;
            // 数据、接口、程序
            case '_DT_MAP': setDtLoading(loadFlag); break;
            case '_DV_MAP': setDvLoading(loadFlag); break;
            case '_CDG_MAP': setCdgLoading(loadFlag); break;
            case '_LSI_MAP': setLsiLoading(loadFlag); break;
            case '_FC_MAP': setFcLoading(loadFlag); break;
            case '_DTO_MAP': setDtoLoading(loadFlag); break;
        }
    }

    const doSearchTotal = async (searchType, keyword) => {
        try {
            const data = await runAsync(
                async () => {
                    return await http.post(`/prjs/search`, { searchType, keyword, prjId: ref.current.prjId, sVer: ref.current.sVer });
                }, {
                error: { content: (err) => `搜索失败: ${err.message}` },
                loading: { show: false },
                success: { show: false },
            });
            return data ?? {};
        } catch (err) {
            console.log(err);
        }
    }

    const doSearchData = async (searchType, keyword, startRow) => {
        try {
            const data = await runAsync(
                async () => {
                    return await http.post(`/prjs/search/data`, { searchType, keyword, startRow, prjId: ref.current.prjId, sVer: ref.current.sVer });
                }, {
                error: { content: (err) => `搜索失败: ${err.message}` },
                loading: { show: false },
                success: { show: false },
            });
            return data.dataList ?? [];
        } catch (err) {
            console.log(err);
        }
    }

    const onCheckAllChange = (e) => {
        setCheckedList(e.target.checked ? options.map(v => v.value) : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    }

    const onChange = (list) => {
        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < options.length);
        setCheckAll(list.length === options.length);
    }

    const BadgeExt = ({ name, dataList, loading }) => {
        return (
            <Badge size="small" offset={[loading ? 2 : 10, 0]} count={dataList.length} overflowCount={9999} status={loading ? 'processing' : 'success'}>
                {name}
            </Badge>
        );
    }

    const paneList = [
        <TabPane key={'BIZ_MAP'} tab={<BadgeExt name="业务地图" dataList={bizMapList} loading={bizMapLoading} />}>
            <BasMapTab nodeType={'BM'} dataList={bizMapList} keyword={ref.current.keyword} rows={bizMapRows} setRows={setBizMapRows} />
        </TabPane>,

        <TabPane key={'DATA_MAP'} tab={<BadgeExt name="数据地图" dataList={dataMapList} loading={dataMapLoading} />}>
            <BasMapTab nodeType={'DM'} dataList={dataMapList} keyword={ref.current.keyword} rows={dataMapRows} setRows={setDataMapRows} />
        </TabPane>,

        <TabPane key={'SF_MAP'} tab={<BadgeExt name="功能地图" dataList={sfMapList} loading={sfMapLoading} />}>
            <BasMapTab nodeType={'FM'} dataList={sfMapList} keyword={ref.current.keyword} rows={sfMapRows} setRows={setSfMapRows} />
        </TabPane>,

        <TabPane key={'UI_MAP'} tab={<BadgeExt name="界面地图" dataList={uiMapList} loading={uiMapLoading} />}>
            <BasMapTab nodeType={'UM'} dataList={uiMapList} keyword={ref.current.keyword} rows={uiMapRows} setRows={setUiMapRows} />
        </TabPane>,

        <TabPane key={'INF_MAP'} tab={<BadgeExt name="接口地图" dataList={infMapList} loading={infMapLoading} />}>
            <BasMapTab nodeType={'LS'} dataList={infMapList} keyword={ref.current.keyword} rows={infMapRows} setRows={setInfMapRows} />
        </TabPane>,

        <TabPane key={'FC_MAP'} tab={<BadgeExt name="程序地图" dataList={fcMapList} loading={fcMapLoading} />}>
            <BasMapTab nodeType={'VM'} dataList={fcMapList} keyword={ref.current.keyword} rows={fcMapRows} setRows={setFcMapRows} />
        </TabPane>,

        <TabPane key={'_DES_MAP'} tab={<BadgeExt name="模型交付单" dataList={desList} loading={desLoading} />}>
            <DesTab nodeType={'DES'} dataList={desList} keyword={ref.current.keyword} rows={desRows} setRows={setDesRows} />
        </TabPane>,

        <TabPane key={'_BF_MAP'} tab={<BadgeExt name="业务流程" dataList={bfList} loading={bfLoading} />}>
            <BfTab dataList={bfList} keyword={ref.current.keyword} rows={bfRows} setRows={setBfRows} />
        </TabPane>,

        <TabPane key={'_BD_MAP'} tab={<BadgeExt name="业务对象" dataList={bdList} loading={bdLoading} />}>
            <BdTab dataList={bdList} keyword={ref.current.keyword} rows={bdRows} setRows={setBdRows} />
        </TabPane>,

        <TabPane key={'_SVR_MAP'} tab={<BadgeExt name="业务服务" dataList={svrList} loading={svrLoading} />}>
            <SvrTab dataList={svrList} keyword={ref.current.keyword} rows={svrRows} setRows={setSvrRows} />
        </TabPane>,

        <TabPane key={'_SF_MAP'} tab={<BadgeExt name="系统功能" dataList={sfList} loading={sfLoading} />}>
            <SfTab dataList={sfList} keyword={ref.current.keyword} rows={sfRows} setRows={setSfRows} />
        </TabPane>,

        <TabPane key={'_UC_MAP'} tab={<BadgeExt name="集成用例" dataList={ucList} loading={ucLoading} />}>
            <UcTab dataList={ucList} keyword={ref.current.keyword} rows={ucRows} setRows={setUcRows} />
        </TabPane>,

        <TabPane key={'_UI_MAP'} tab={<BadgeExt name="用户界面" dataList={uiList} loading={uiLoading} />}>
            <UiTab dataList={uiList} keyword={ref.current.keyword} rows={uiRows} setRows={setUiRows} />
        </TabPane>,

        <TabPane key={'_DT_MAP'} tab={<BadgeExt name="数据表" dataList={dtList} loading={dtLoading} />}>
            <DtTab dataList={dtList} keyword={ref.current.keyword} rows={dtRows} setRows={setDtRows} />
        </TabPane>,

        <TabPane key={'_DV_MAP'} tab={<BadgeExt name="数据视图" dataList={dvList} loading={dvLoading} />}>
            <DvTab dataList={dvList} keyword={ref.current.keyword} rows={dvRows} setRows={setDvRows} />
        </TabPane>,

        <TabPane key={'_CDG_MAP'} tab={<BadgeExt name="标准字段组" dataList={cdgList} loading={cdgLoading} />}>
            <CdgTab dataList={cdgList} keyword={ref.current.keyword} rows={cdgRows} setRows={setCdgRows} />
        </TabPane>,

        <TabPane key={'_LSI_MAP'} tab={<BadgeExt name="外联接口" dataList={lsiList} loading={lsiLoading} />}>
            <LsiTab dataList={lsiList} keyword={ref.current.keyword} rows={lsiRows} setRows={setLsiRows} />
        </TabPane>,

        <TabPane key={'_FC_MAP'} tab={<BadgeExt name="程序能力" dataList={fcList} loading={fcLoading} />}>
            <FcTab dataList={fcList} keyword={ref.current.keyword} rows={fcRows} setRows={setFcRows} />
        </TabPane>,

        <TabPane key={'_DTO_MAP'} tab={<BadgeExt name="DTO" dataList={dtoList} loading={dtoLoading} />}>
            <DtoTab dataList={dtoList} keyword={ref.current.keyword} rows={dtoRows} setRows={setDtoRows} />
        </TabPane>,
    ].filter(v => checkedList.includes(v.key));

    const searchInput = {
        width: '600px',
        borderRadius: '16px 0 0 16px',
        borderRight: 'none',
    }

    const searchBtn = {
        display: 'inline-block',
        width: '88px',
        height: '40px',
        outline: 0,
        border: '0 none',
        borderRadius: '0 16px 16px 0',
        fontSize: '14px',
        lineHeight: '32px',
        cursor: 'pointer',
        transition: 'all .2s ease -in',
        backgroundColor: '#0099CC',
        textAlign: 'center',
        color: '#fff',
    }

    const cssSearch = {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px',
    }

    const cssContent = {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px',
        marginLeft: '4px',
        height: '75vh',
    }

    return <div>
        <div css={{ display: 'flex', justifyContent: 'center', marginTop: '20px', fontSize: '12pt', fontWeight: 'bold' }}>
            <div>
                <span>项目：</span>
                <SelectProjectList
                    style={{ width: '400px' }}
                    prjId={ref.current.prjId}
                    onCall={(value) => {
                        ref.current.prjId = value.pkid;
                        ref.current.sVer = value.sVer;
                        clearSearch();
                    }} />
            </div>
        </div>

        <div css={cssSearch}>
            <Input
                css={searchInput}
                size="large"
                allowClear
                enterButton
                defaultValue={keyword}
                prefix={<SearchOutlined />}
                onChange={e => ref.current.keyword = e.target.value}
                onPressEnter={e => doSearch(e.target.value)}
            />
            <button css={searchBtn} onClick={() => doSearch(ref.current.keyword)}>搜索</button>
        </div>

        <div css={cssSearch} style={{ marginTop: '10px' }}>
            <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>全部</Checkbox>
            {/* <Checkbox.Group value={checkedList} onChange={onChange} options={options} /> */}
            <Checkbox.Group value={checkedList} onChange={onChange}>
                <Row>
                    <Checkbox value="BIZ_MAP">业务地图</Checkbox>
                    <Checkbox value="DATA_MAP">数据地图</Checkbox>
                    <Checkbox value="SF_MAP">功能地图</Checkbox>
                    <Checkbox value="UI_MAP">界面地图</Checkbox>
                    <Checkbox value="INF_MAP">接口地图</Checkbox>
                    <Checkbox value="FC_MAP">程序地图</Checkbox>
                </Row>
                <Row>
                    <Checkbox value="_DES_MAP">模型交付单</Checkbox>
                    <Checkbox value="_BF_MAP">业务流程</Checkbox>
                    <Checkbox value="_BD_MAP">业务对象</Checkbox>
                    <Checkbox value="_SVR_MAP">业务服务</Checkbox>

                    <Checkbox value="_SF_MAP">系统功能</Checkbox>
                    <Checkbox value="_UC_MAP">集成用例</Checkbox>
                    <Checkbox value="_UI_MAP">用户界面</Checkbox>

                    <Checkbox value="_DT_MAP">数据表</Checkbox>
                    <Checkbox value="_DV_MAP">数据视图</Checkbox>
                    <Checkbox value="_CDG_MAP">标准字段组</Checkbox>

                    <Checkbox value="_LSI_MAP">外联接口</Checkbox>
                    <Checkbox value="_FC_MAP">程序能力</Checkbox>
                    <Checkbox value="_DTO_MAP">DTO</Checkbox>
                </Row>
            </Checkbox.Group>
        </div>

        {
            (0 == ref.current.count) ?
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>请按“回车”键发起检索</div>
                :
                <div css={cssContent}>
                    <Tabs type="list" tabPosition={'top'} size="large">
                        {
                            paneList.map(v => v)
                        }
                    </Tabs>
                </div>
        }
    </div >;
}

export default Pane;
