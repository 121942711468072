import { useSearchParams } from 'react-router-dom';
import SoftwareMap from '@/components/SoftwareMap/SoftwareMap';
import useDocTitle from '@/hooks/useDocTitle.mjs';

const Pane = () => {
    const [searchParams] = useSearchParams()
    const param = Object.fromEntries(searchParams.entries());

    // 获取URL参数
    const id = param.id;
    const name = param.name;
    const type = param.type;
    const mapPath = param.mapPath;
    useDocTitle(name);

    return <SoftwareMap id={id} type={type} mapPath={mapPath} style={{ width: '100vw', height: '100vh' }} />;
}

export default Pane;
