import React from 'react';
// import { Table } from 'antd';
import openWindow from '@/script/openWindow.mjs';
import Page from '@/script/Page';
import Keyword from './Keyword.jsx';
import Table from './SearchTable';

const Pane = ({ dataList, keyword, rows, setRows }) => {
    const onLink = (record) => {
        // openWindow('/UiDetail', { prjId: record.prjId, uiId: record.dpId, uiName: record.dpName, mapPath: record.mapPath });
        openWindow('/UiDetail', { uiId: record.dpId, mapPath: record.mapPath });
    }

    const columns = [
        {
            key: 'ssName',
            dataIndex: 'ssName',
            title: '子系统',
            width: '100px'
        },
        {
            key: 'fmNamePath',
            dataIndex: 'fmNamePath',
            title: '功能模块',
            width: '100px'
        },
        {
            key: 'dpCode',
            dataIndex: 'dpCode',
            title: '用户界面代码',
            width: '100px'
        },
        {
            key: 'dpName',
            dataIndex: 'dpName',
            title: '用户界面名称',
            width: '100px'
        },
        {
            key: 'keywordPath',
            dataIndex: 'keywordPath',
            title: '节点',
            onCell: (record, index) => {
                return { style: { whiteSpace: 'wrap' } };
            },
            render: (text, record) => {
                // record.mapPath = '/' + encodeURIComponent(record.dpName) + record.keywordEncodePath;
                record.mapPath = record.keywordEncodePath;
                return <a onClick={() => onLink(record)}><Keyword data={text.substr(0, text.length - 1)} keyword={keyword} /></a>;
            }
        }
    ]

    const rowSelection = {
        selectedRowKeys: rows.map(({ pkid }) => pkid),
        onChange: (selectedRowKeys, selectedRows) => {
            setRows(selectedRows);
        },
    }

    return (
        <Table
            size="small"
            columns={columns}
            dataSource={dataList}
            pagination={Page.init(dataList)}
            rowKey={record => record.pkid}
            rowSelection={{ type: "checkbox", ...rowSelection }}
        />
    );
}

export default Pane;