import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { css } from '@emotion/react'
import { Link } from 'react-router-dom'
import { Layout, Input, Space } from 'antd';
import { SearchOutlined, ArrowRightOutlined } from '@ant-design/icons';
import MainMenu from './MainMenu';
import favicon from '/favicon.png';
import {useUser, setUser} from '@/biz/user.mjs'
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from "@/script/runAsync.mjs";
import openWindow from '@/script/openWindow.mjs';
import SelectProjectDialog from '@/pages/MainPage/dialogs/SelectProjectDialog';

const cssTitle = css({
    fontSize: 20,
    color: '#fff',
    padding: '0 15px',
    display: 'flex',
    alignItems: 'center'
})

const cssLink = css({
    fontWeight: 'bold',
    color: '#fff',
    display: 'flex',
    alignItems: 'center'
})

const cssLogo = css({
    height: 22,
    marginRight: 8,
})

const cssProject = css({
    color: '#fff',
    fontSize: 16,
    marginLeft: 20,
})

const cssProjectLink = css({
    color: '#fff',
    fontSize: 16,
    marginLeft: 10,
})

const MainTitle = () => {
    const navigate = useNavigate();
    const http = useHTTP();
    const user = useUser()
    const [visible, setVisible] = useState(false);

    const callPrj = async (value) => {
        if (value) {
            try {
                const data = await runAsync(
                    async () => {
                        return await http.put(`/tmusers/modifyPrj`, { pkid: user?.pkid, currPrjId: value.pkid });
                    }, {
                    error: { content: (err) => `设置失败: ${err.message}` },
                    loading: { show: false },
                    success: { show: true },
                });

                setUser({
                    ...user,
                    currPrjId: data.currPrjId,
                    currPrjSver: data.currPrjSver,
                    currPrjNo: data.currPrjNo,
                    currPrjName: data.currPrjName,
                    prjTypeCode: data.prjTypeCode,
                });

                // if (location.pathname.includes('/SoftwareMap')) {
                //     // navigate('/');
                //     navigation.navigate('/');
                // }
            } catch (err) {
                console.log(err);
            }
        }
    }

    const linkPrj = () => {
        openWindow('/ProjectDetail', { prjId: user?.currPrjId, prjNo: user?.currPrjNo });
    }

    return (
        <div css={cssTitle}>
            <Link
                css={cssLink}
                reloadDocument
                to="/"
                title={'进入工作台'}
            >
                <img
                    css={cssLogo}
                    alt="logo"
                    src={favicon}
                />
                软件逻辑地图
            </Link>

            <a css={cssProject} onClick={() => setVisible(true)} title={"切换当前项目"}>{user?.currPrjNo}[当前项目]</a>
            {user?.currPrjNo && <a css={cssProjectLink} onClick={linkPrj} title={"进入项目"}><ArrowRightOutlined/></a>}
            {
                user?.currPrjNo &&
                <Input
                    allowClear
                    prefix={<SearchOutlined />}
                    style={{ width: 200, marginLeft: '20px', borderRadius: '16px' }}
                    onPressEnter={(e) => openWindow('/PrjSearch', { keyword: e.target.value })}
                />
            }

            <SelectProjectDialog visible={visible} setVisible={setVisible} onCall={callPrj} prjStsCodes={['DRAFT', 'RLS']} />
        </div>
    );
};

const MainHeader = () => {
    const cssHeader = {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        padding: 0,
    };

    return (
        <Layout.Header css={cssHeader}>
            <MainTitle />
            <MainMenu />
        </Layout.Header>
    );
};

export default MainHeader;
