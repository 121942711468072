import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Select, Modal, Row, Col, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Table from '@/pages/MainPage/components/Table';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from '@/script/runAsync';
import Page from '@/script/Page';

/**
 * SelectProjectDialog ：选择项目【单选】
 * 
 * 使用场景：
 * 1：界面管理->新增用户界面，选择项目；
 * 
 * 参数说明：
 * visible:     【必填】 当前Dialog是否展示 
 * setVisible:  【必填】 当前Dialog展示的操作方法 
 * onCall:      【必填】 回调方法 
 * isSingle:    【可选】 true(单选、默认)  false(多选)
 * prjTypeCode: 【可选】 项目类型
 * title:       【可选】 弹窗标题
 * 
 * @param {*} param
 * @returns 单选，返回对象；多选返回数组；
 **/
const SelectProjectDialog = ({ visible, setVisible, onCall, isSingle = true, prjStsCodes, prjTypeCode, title = "选择项目", ...props }) => {
    const http = useHTTP();
    // const { selectItems } = props; // 【可选】 被选择的条目，参数类型可为对象或数组
    // const { visibleHome } = props;

    // 表单
    const [form] = Form.useForm();

    // 数据模型
    const [projects, setProjects] = useState([]);

    // 加载显示
    const [loading, setLoading] = useState(false);
    const [expand, setExpand] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]); // 选中的对象集合

    // 组件初始化
    useEffect(() => {
        if (visible) {
            doSearch();
            setSelectedRows([]); // 处理被选中的项目，当主Dialog关闭，需要清空选择的数据
        }
    }, [visible]);

    const doSearch = async () => {
        try {
            setLoading(true);
            const formField = await form.validateFields();

            if (prjStsCodes) {
                formField.prjStsCodes = prjStsCodes;
            } else {
                formField.prjStsCode = 'RLS';
            }

            if(prjTypeCode){
                formField.prjTypeCode = prjTypeCode;
            }

            const data = await runAsync(
                async () => {
                    return await http.get(`/prjs`, formField);
                }, {
                error: { content: (err) => `查询项目失败: ${err.message}` },
                loading: { show: false },
                success: { show: false },
            });

            setProjects(data);

            // if (null != selectItems && selectItems != undefined) {
            //     if (Array.isArray(selectItems)) {
            //         setSelectedRows(selectItems);
            //     } else {
            //         setSelectedRows([selectItems]);
            //     }
            // }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    const doReset = async () => {
        doSearch();
        setSelectedRows([]);
        form.resetFields();
    }

    // 确认回调
    const doConfirm = async () => {
        if (isSingle) {
            if (selectedRows.length == 0) {
                onCall(null);
            } else {
                onCall(selectedRows[0]);
            }
        } else {
            onCall(selectedRows);
        }

        doClose();
    }

    // 关闭Dialog
    const doClose = () => {
        form.resetFields();
        setVisible(false);
        setSelectedRows([]);
    }

    const rowSelection = {
        type: isSingle ? 'radio' : 'checkbox',
        selectedRowKeys: selectedRows.map(({ pkid }) => pkid),
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
        },
    }

    const columns = [
        {
            title: '项目编号',
            dataIndex: 'prjNo',
        },
        {
            title: '项目名称',
            dataIndex: 'prjName',
        },
        {
            title: '基线号',
            dataIndex: 'sVer',
        },
        {
            title: '项目类型',
            dataIndex: 'typeName',
        },
        {
            title: '项目状态',
            dataIndex: 'stsName',
        },
    ];

    return (
        <>
            <Modal
                visible={visible}
                title={title}
                width={1000}
                onOk={doConfirm}
                onCancel={() => setVisible(false)}
                bodyStyle={{ background: '#f0f2f5', padding: '12px' }}
            >
                {/* 查询条件 */}
                <div style={{ padding: '24px 24px 0 24px', overflow: 'hidden', background: '#fff' }}>
                    <Form form={form} onFinish={doSearch}>
                        <Row gutter={24}>
                            <Col span={6}>
                                <Form.Item name="prjNo" label="项目编号">
                                    <Input size="middle" placeholder="请输入" />
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Form.Item name="prjName" label="项目名称">
                                    <Input size="middle" placeholder="请输入" />
                                </Form.Item>
                            </Col>

                            {/* <Col span={6}>
                                <Form.Item name="prjStsCode" label="项目状态">
                                    <Select placeholder="请选择">
                                        <Option value="DRAFT">草稿</Option>
                                        <Option value="RLS">发布</Option>
                                        <Option value="END">结案</Option>
                                    </Select>
                                </Form.Item>
                            </Col> */}

                            <Col span={12} offset={expand ? 18 : 0} style={{ textAlign: 'right' }}>
                                <Button onClick={doReset} style={{ margin: '0 8px' }}>重置</Button>
                                <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>查询</Button>
                            </Col>
                        </Row>
                    </Form>
                </div>

                {/* 数据表格 */}
                <div style={{ marginTop: '12px', overflow: 'auto', height: '400px', background: '#fff' }}>
                    {/* 统计区 */}
                    {
                        selectedRows.length > 0 &&
                        <div className="smstable-body-statis">
                            <Space>
                                <span>已选择</span>
                                <span>{selectedRows.length}</span>
                                <span>项</span>
                            </Space>
                            <Space>
                                <a onClick={() => setSelectedRows([])}>取消选择</a>
                            </Space>
                        </div>
                    }

                    {/* 数据区 */}
                    <Table
                        size="middle"
                        loading={loading}
                        columns={columns}
                        dataSource={projects}
                        pagination={Page.init(projects)}
                        rowKey={record => record.pkid}
                        rowSelection={{ type: "checkbox", ...rowSelection }}
                    />
                </div>
            </Modal>
        </>
    );
}

export default SelectProjectDialog;