import {useEffect} from 'react'

export default (title, isAlone = false) => {
    useEffect(() => {
        if (title) {
            const titles = title instanceof Array ? [...title] : [title]
            if (!isAlone) {
                titles.push(import.meta.env.VITE_APP_NAME)
            }
            document.title = titles.join("-")
        } else {
            document.title = import.meta.env.VITE_APP_NAME
        }
    }, [title])
}
